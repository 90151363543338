import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const AboutPersonTemplate = ({
  content,
  contentComponent,
  name,
  image,
  position,
  subtitle,
  links,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section" style={{ marginTop: "7rem" }}>
      {helmet || ""}
      <div className={"section"}>
        <div className={"columns"}>
          <div className={"column is-4"}>
            <div className="section box has-background-primary">
              <div className={"has-text-centered"}>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "240px",
                    display: "inline-block",
                  }}
                >
                  <PreviewCompatibleImage
                    imageInfo={{ image, alt: `${name}` }}
                  />
                </div>
                <div className={"buttons mt-4"}>
                  {Array.isArray(links)
                    ? links.map((link, linkIdx) => (
                        <a
                          key={`${linkIdx}-employee-link`}
                          href={link.link}
                          className={
                            "button has-text-weight-bold is-white is-outlined is-rounded is-fullwidth"
                          }
                        >
                          {link.name}
                        </a>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className="column is-8">
            <div className="">
              <p className="is-size-1 has-text-weight-bold">{name}</p>
              <p className="is-size-3 has-text-weight-bold is-bold-light">
                {position}
              </p>
              <p className="is-size-5">{subtitle}</p>
              <PostContent content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AboutPersonTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const AboutPerson = ({ data }) => {
  const { markdownRemark: person } = data;

  return (
    <Layout>
      <AboutPersonTemplate
        content={person.html}
        contentComponent={HTMLContent}
        name={person.frontmatter.name}
        image={person.frontmatter.image}
        position={person.frontmatter.position}
        subtitle={person.frontmatter.subtitle}
        links={person.frontmatter.links}
        helmet={
          <Helmet titleTemplate={`${person.frontmatter.name} | About`}>
            <title>{`${person.frontmatter.name}`}</title>
            <meta
              name="description"
              content={`${person.frontmatter.position}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

AboutPerson.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default AboutPerson;

export const pageQuery = graphql`
  query AboutPersonByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 200, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        name
        position
        subtitle
        links {
          name
          link
        }
      }
    }
  }
`;
